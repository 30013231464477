'use client';

import { array, object, string } from 'prop-types';
import { useCallback, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

import { useLocalizedPathname, useValidators } from '@personly/libs-hooks';
import { InputIcon } from '@personly/libs-widgets';
import { magnifyIcon } from '@personly/libs-icons';

const initialQ = (searchParams) =>
  searchParams.has('q') ? searchParams.get('q') : '';

const SearchInput = ({
  languages,
  role = 'card',
  placeholder = 'Search',
  validators,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const localizedPathname = useLocalizedPathname(languages);
  const { range } = useValidators(validators);

  const [q, setQ] = useState(initialQ(searchParams));
  const searchMinMax = range('SEARCH');

  const onClick = useCallback(() => {
    const queryParams = new URLSearchParams();
    for (const [key, value] of searchParams) {
      queryParams.set(key, value);
    }

    queryParams.set('q', q);
    queryParams.set('page', 1);

    router.push(localizedPathname(`/search?${queryParams.toString()}`));
  }, [q, searchParams, router, localizedPathname]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') onClick();
    },
    [onClick]
  );

  const onChange = useCallback((e) => setQ(e.target.value), []);

  return (
    <InputIcon
      role={role}
      type={'text'}
      placeholder={placeholder}
      name="search"
      id="search"
      value={q}
      maxLength={searchMinMax.max}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      icon={magnifyIcon}
      label="Begin Search"
      onClick={onClick}
    />
  );
};

SearchInput.propTypes = {
  languages: object.isRequired,
  placeholder: string,
  role: string,
  validators: array.isRequired,
};

export default SearchInput;
