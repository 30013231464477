'use client';

import { useCallback, useMemo } from 'react';

const useValidators = (validatorsList) => {
  const validators = useMemo(
    () =>
      validatorsList.reduce(
        (acc, cur) => ({ ...acc, [cur.handle]: { ...cur } }),
        {}
      ),
    [validatorsList]
  );

  const requirement = useCallback(
    (handle) => {
      const validator = validators[handle];
      const eachRequirement = [];
      if (validator.regex) eachRequirement.push('inputted value is not valid');
      if (validator.min)
        eachRequirement.push(`min of ${validator.min} characters`);
      if (validator.max)
        eachRequirement.push(`max of ${validator.max} characters`);
      if (validator.typeHandle === 'URL')
        eachRequirement.push('must be a valid URL');
      if (validator.values && validator.values.length > 0) {
        eachRequirement.push(`must be one of ${validator.values.join(',')}`);
      }

      if (eachRequirement.length === 1) return eachRequirement[0];

      const copy = [...eachRequirement];
      copy.pop();

      return copy.join(', ').concat(', and ').concat(eachRequirement.pop());
    },
    [validators]
  );

  const validatorValues = useCallback(
    (handle) => {
      const validator = validators[handle];
      return validator.values || [];
    },
    [validators]
  );

  const validate = useCallback(
    (handle, value) => {
      const validator = validators[handle];

      if (validator.typeHandle === 'URL') {
        if (validator.max && value.length > validator.max) return false;

        try {
          new URL(value);
          return true;
        } catch (err) {
          return false;
        }
      }

      if (validator.typeHandle !== 'TEXT') return false;
      if (validator.min && value.length < validator.min) return false;
      if (validator.max && value.length > validator.max) return false;
      if (validator.regex) {
        const rexp = new RegExp(validator.regex, 'i');
        if (!rexp.test(value)) return false;
      }

      return true;
    },
    [validators]
  );

  const range = useCallback(
    (handle) => {
      const validator = validators[handle];
      if (validator.typeHandle === 'TEXT')
        return { min: validator.min, max: validator.max };
      if (validator.typeHandle === 'DATE') {
        const year = new Date().getUTCFullYear();
        return { min: year + validator.min, max: year + validator.max };
      }
      return { min: 0, max: 0 };
    },
    [validators]
  );

  const value = useMemo(
    () => ({ validate, requirement, range, validatorValues }),
    [validate, requirement, range, validatorValues]
  );

  return value;
};

export default useValidators;
