import { useCallback } from 'react';

import { useResources } from '@personly/libs-providers';

const useTickets = () => {
  const resources = useResources();

  const createTicket = useCallback(
    ({ email, body }, cb) => {
      resources(
        async ({ clients, messages }) => {
          const tickets = await messages.tickets;
          const client = await clients.tickets;

          const request = new tickets.CreateTicketRequest();
          request.setEmail(email);
          request.setBody(body);

          const response = await client.createTicket(request);
          const { result } = response.toObject();

          cb(result);
        },
        ['CREATE_TICKET']
      );
    },
    [resources]
  );

  return { createTicket };
};

export default useTickets;
