import { useCallback, useReducer } from 'react';

import { useResources, useStreams } from '@personly/libs-providers';

const initialState = { data: [], count: 0 };

const flattenMetadata = (input) => {
  const metadata = input.metadataMap.reduce(
    (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
    {}
  );

  delete input.fileSourcesMap;

  return { ...input, metadata };
};

const flattenFileSources = (input) => {
  const fileSources = input.fileSourcesMap.reduce(
    (acc, cur) => ({ ...acc, [cur[0]]: flattenMetadata(cur[1]) }),
    {}
  );

  delete input.fileSourcesMap;

  return { ...input, fileSources };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'PERSONALS_CLEARED': {
      return { data: [], count: 0 };
    }

    case 'PERSONALS_FETCHED': {
      return {
        ...state,
        data: [...state.data, ...action.data],
        count: action.count,
      };
    }

    case 'PERSONAL_STREAMED': {
      const { id, statusHandle } = action.data;

      const copy = JSON.parse(JSON.stringify(state.data));
      const i = copy.findIndex((personal) => personal.id === id);

      if (i > -1) {
        const shouldRemove = ['CANCELLED', 'FILLED'].includes(statusHandle);
        if (shouldRemove) copy.splice(i, 1);
      } else copy.push(action.data);

      return { ...state, personals: copy };
    }

    default:
      return state;
  }
};

const usePersonals = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resources = useResources();
  const streams = useStreams();

  const searchPersonals = useCallback(
    (search, languageHandle, containsMatureContent, { limit, page }) =>
      resources(async ({ clients, messages }) => {
        const personals = await messages.personals;
        const client = await clients.personals;

        const paginateRequest = new (await messages.paginate).Paginate();
        paginateRequest.setLimit(limit);
        paginateRequest.setPage(page);

        const request = new personals.SearchPersonalsRequest();
        request.setLanguageHandle(languageHandle);
        request.setContainsMatureContent(containsMatureContent);
        request.setSearch(search);
        request.setPaginate(paginateRequest);

        const response = await client.searchPersonals(request, {});
        const { count, resultList } = response.toObject();

        if (page === 1) {
          dispatch({ type: 'PERSONALS_CLEARED' });
        }

        const data = resultList.map((result) => {
          const flags = result.flagsMap.reduce(
            (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
            {}
          );

          if (result.organization) {
            result.organization = flattenFileSources(result.organization);
          }

          return { ...result, flags };
        });

        dispatch({ type: 'PERSONALS_FETCHED', data, count });
      }),
    [resources]
  );

  const subscribePersonals = useCallback(
    () =>
      streams.subscribe('APPS-PERSONALS', 'PERSONAL-UPDATED', (data) =>
        dispatch({ type: 'PERSONAL_STREAMED', data })
      ),
    [streams]
  );

  const unsubscribePersonals = useCallback(
    (handlePromise) =>
      handlePromise.then((handle) => streams.unsubscribe(handle)),
    [streams]
  );

  return {
    count: state.count,
    personals: state.data,
    searchPersonals,
    subscribePersonals,
    unsubscribePersonals,
  };
};

export default usePersonals;
