import articleIcon from './article.js';
import commentIcon from './comment.js';
import continentIcon from './continent.js';
import currencyIcon from './currency.js';
import genderIcon from './gender.js';
import inboxIcon from './inbox.js';
import matureContentIcon from './matureContent.js';
import menuIcon from './menu.js';
import messageIcon from './message.js';
import paymentMethodIcon from './paymentMethod.js';
import peopleIcon from './people.js';
import personalTypeIcon from './personalType.js';
import postIcon from './post.js';
import promoIcon from './promo.js';
import schemeIcon from './scheme.js';
import shareIcon from './share.js';
import socialIcon from './social.js';
import toggleIcon from './toggle.js';
import uploadIcon from './upload.js';
import userIcon from './user.js';
import verifiedIcon from './verified.js';
import visibilityIcon from './visibility.js';

const addIcon = 'plus';
const removeIcon = 'trash';
const redoIcon = 'rotate';
const undoIcon = 'rotate-left';
const copyIcon = 'copy';
const filterIcon = 'filter';

const enterIcon = 'right-to-bracket';
const exitIcon = 'right-from-bracket';

const openIcon = 'arrow-up-right-from-square';
const closeIcon = 'xmark';

const backwardIcon = 'angles-left';
const forwardIcon = 'angles-right';
const downIcon = 'angle-down';

const settingsIcon = 'gear';

const timerIcon = 'timer';

const coinsIcon = 'coins';

const receiptIcon = 'receipt';
const clockIcon = 'clock';

const idIcon = 'id-badge';

const statusIcon = 'arrows-rotate';

const emailIcon = 'at';
const envelopeIcon = 'envelope';

const globeIcon = 'globe';

const downloadIcon = 'download';
const addressIcon = 'address-card';
const medalIcon = 'medal';
const champagneIcon = 'champagne-glasses';
const flameIcon = 'fire-flame-curved';
const bellIcon = 'bell';
const mobileIcon = 'mobile';
const magnifyIcon = 'magnifying-glass';
const imagesIcon = 'images';
const historyIcon = 'history';
const briefcaseIcon = 'briefcase-blank';
const lockIcon = 'lock';
const boxOpenIcon = 'box-open';
const tagIcon = 'tag';
const badgePercentIcon = 'badge-percent';
const rightLeftIcon = 'right-left';
const languageIcon = 'language';
const handshakeIcon = 'handshake-simple';
const calendarRangeIcon = 'calendar-range';
const linkIcon = 'link';
const blogIcon = 'blog';
const listOlIcon = 'list-ol';

export {
  addIcon,
  addressIcon,
  articleIcon,
  backwardIcon,
  badgePercentIcon,
  bellIcon,
  blogIcon,
  boxOpenIcon,
  briefcaseIcon,
  calendarRangeIcon,
  champagneIcon,
  clockIcon,
  closeIcon,
  coinsIcon,
  commentIcon,
  continentIcon,
  copyIcon,
  currencyIcon,
  downIcon,
  downloadIcon,
  emailIcon,
  enterIcon,
  envelopeIcon,
  exitIcon,
  filterIcon,
  flameIcon,
  forwardIcon,
  genderIcon,
  globeIcon,
  handshakeIcon,
  historyIcon,
  idIcon,
  imagesIcon,
  inboxIcon,
  languageIcon,
  linkIcon,
  listOlIcon,
  lockIcon,
  magnifyIcon,
  matureContentIcon,
  medalIcon,
  menuIcon,
  messageIcon,
  mobileIcon,
  openIcon,
  paymentMethodIcon,
  peopleIcon,
  personalTypeIcon,
  postIcon,
  promoIcon,
  receiptIcon,
  redoIcon,
  removeIcon,
  rightLeftIcon,
  schemeIcon,
  settingsIcon,
  shareIcon,
  socialIcon,
  statusIcon,
  tagIcon,
  timerIcon,
  toggleIcon,
  undoIcon,
  uploadIcon,
  userIcon,
  verifiedIcon,
  visibilityIcon,
};
