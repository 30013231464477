import { useCallback, useReducer } from 'react';

import { useResources } from '@personly/libs-providers';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFERRAL_CODE_FETCHED': {
      return action.data;
    }

    default:
      return state;
  }
};

const useReferralCodes = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resources = useResources();

  const getReferralCode = useCallback(
    (id) =>
      resources(async ({ clients, messages }) => {
        const referralCodes = await messages.referralCodes;
        const client = await clients.referralCodes;

        const request = new referralCodes.GetReferralCodeRequest();
        request.setId(id);

        const response = await client.getReferralCode(request, {});
        const { result } = response.toObject();

        dispatch({ type: 'REFERRAL_CODE_FETCHED', data: result });
      }),
    [resources]
  );

  return { referralCode: state, getReferralCode };
};

export default useReferralCodes;
